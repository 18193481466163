import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column; /* Added */
`;

const Image = styled.img`
  width: 200px; /* Adjust the size of the image as needed */
`;

const Title = styled.h2`
  font-size: 14px;
  margin-top: 10px;
`;

function App() {
  return (
    <Container>
      <Image src="/bwh.gif" alt="bwh" />
      <Title> BRIAN WIF HAIR $BWIH </Title>
      <Title> BETTER THAN BALD </Title>
      <Title> SUPPLY: 10,000,000,  LIQUIDITY LOCKED, CONTRACT RENOUNCED, TAX 0%, BASE CHAIN, NO ROADMAP, NO RUG</Title>
      <Title> CA: 0xd56522f0BD25A4F6382baE83733ba08CdB683BC0 </Title>
      <Title> <a href="https://app.uniswap.org/explore/tokens/base/0xd56522f0bd25a4f6382bae83733ba08cdb683bc0"> BUY ON UNISWAP </a> </Title>
      <Title> <a href="https://dexscreener.com/base/0xd56522f0BD25A4F6382baE83733ba08CdB683BC0"> DEXSCREENER </a> </Title>
      <Title> <a href="https://t.me/brianwifhairbase"> TELEGRAM </a></Title>
      <Title> <a href="https://warpcast.com/~/channel/brianwifhair"> FARCASTER </a> </Title>
      <Title> <a href="https://x.com/thebrianwifhair"> TWITTER </a></Title> 
    </Container>
  );
}

export default App;